/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

          $('.toplink').click(function(e){
            e.preventDefault();
            $('html, body').animate({scrollTop:0}, 700, 'easeInOutExpo');
          });



           //more TEXT toggler:
          $('.toggleLink').click(function(e){
            //console.info("fuck");
              e.preventDefault();
              var textswap = $(this).data("text-swap");
              var textnow = $(this).text();

              $(this).data("text-swap", textnow);
              $(this).text(textswap);
             // $('.team').toggleClass('show');
          });


      // function toggle Hamburger on Hover:
      /*
      function toggleNavbarMethod() {
        if ($(window).width() > 768) {
            $('.navbar .navbar-toggle').on('mouseover', function(){
                $(this).trigger('click');
            }).on('mouseout', function(){
                $(this).trigger('click').blur();
            });
        }
        else {
            $('.navbar .navbar-toggle').off('mouseover').off('mouseout');
        }
    }

     // toggle navbar hover
        toggleNavbarMethod();

        // bind resize event
        $(window).resize(toggleNavbarMethod);
      */

      /*
       $(document).on('mouseenter.collapse', '.navbar .navbar-toggle', function(e) {
        var $this = $(this),
            href, target = $this.attr('data-target') || e.preventDefault() || (href = $this.attr('href')) && href.replace(/.*(?=#[^\s]+$)/, ''),
            option = $(target).hasClass('in') ? 'hide' : "show";
            $('.panel-collapse').not(target).collapse("hide");
            $(target).collapse(option);
       });
    */


       // BACKGROUNDCOLOR:
        var farbe;
        if ( $('body').data('color')  ){
           farbe = $('body').data('color');
        }else{
           farbe = '#fefef1';
        }
        // JavaScript to be fired on the about us page
        $('body').css({'background-color': farbe});

         if ( $('body').data('timing')  ){
              timing = $('body').data('timing');

               $('body').css({'-webkit-transition-duration' : timing+'s' , 'transition-duration' : timing+'s'});
               
          }



           if ($('#searchoverlay .search-field').length) { 
                // AJAX SEARCH:
                function initsearch(){

                    var searchTimeout,
                    ajaxUrl = '/cms/wp-admin/admin-ajax.php',
                    $searchInput = $('#searchoverlay .search-field'), //input[name=s]
                    $searchResults = $('#searchresults');

                    $searchInput.on('change keyup', function() {
                        clearTimeout(searchTimeout);
                        searchTimeout = setTimeout(function() {
                            instantSearch();
                        }, 250);
                    }).on('keypress', function(e) {
                          return e.keyCode !== 13;
                      });

                    function instantSearch() {
                        var value = $searchInput.val();
                        if (value.length > 1) {
                            $searchResults.html('<div class="spinner"></div>');
                            $.post(ajaxUrl, {action: 'ajax_search', s: value}, function(output) {
                                $searchResults.html(output);
                            });
                        }
                    }

                    setTimeout(function () {
                      $searchInput.focus();
                    }, 300);


                }
                initsearch();
               // end ajax search
           }

     






      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

       /* var $container = $('.main');
        // init
        $container.packery({
          itemSelector: '.griditem',
          gutter: 10
        });
        */

        function orderPosts(container) {
              var $grp = $('.main'),
                  neworder = [],
                  ordered = $grp.children().toArray();

                  while(ordered.length > 0) {
                      var idx = Math.floor((Math.random() * (ordered.length)));
                      var element = ordered.splice(idx, 1);
                      neworder.push(element[0]);
                  }


              $grp.empty().append(neworder);
              console.info(neworder);

               $container.packery('reloadItems');
               $container.packery();

          }
        function timeout() {
            setTimeout(function () {
                // Do Something Here
                orderPosts();
                // Then recall the parent function to
                // create a recursive loop.
                timeout();
            }, 5000);
        }

        var $container = $('.main');
        /*
        var $container = $('.main').imagesLoaded( function() {
          // initialize Packery after all images have loaded
          $container.packery({
            itemSelector: '.griditem',
            gutter: 10,
            //"columnWidth": ".grid-sizer"
            //percentPosition: true
          });

          setTimeout(orderPosts, 2000);
          //timeout();
          //orderPosts('.main');

        });
        */

        var i; 
         $container.imagesLoaded().progress( function(instance , image) {
              //$(image.img).parent().addClass('in');
              //$(image.img).addClass('in');
              //$(image.img).closest( ".fade" ).addClass('in');
              var item = $(image.img).parent().parent();

              $(item).delay((i++) * 100).queue(function() { $(item).addClass('in'); });
              //$(item).addClass('in');

                 var result = image.isLoaded ? 'loaded' : 'broken';
                console.info( 'image is ' + result + ' for ' + image.img.src );

              //$container.masonry('layout');
              $container.packery({
                  itemSelector: '.griditem',
                  gutter: 10,
                  //"columnWidth": ".grid-sizer"
                  //percentPosition: true
                });


              setTimeout(orderPosts, 3000);
            });



        







      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'single': {
      init: function() {

       

      }
    },

    'suche' : {
        init: function(){






        }

    },
    // About us page, note the change from about-us to about_us.
    'blog': {
      init: function() {
        // JavaScript to be fired on the about us page

            //console

             $(".werkliste").tablesorter({
                  // sort on the first column and third column, order asc
                  sortList: [[0,1]],
                  /* textExtraction: function(node) {
                      // extract '-' from markup and return it
                      //console.info(node.innerHTML);
                      return node.innerHTML.replace('-', '');
                  }*/

            });


             /* FILTER */
         $(".filterbtn").click(function(e){
                e.preventDefault();
               $(this).toggleClass('active').siblings().removeClass('active');
              //$(this).addClass('active').siblings().removeClass('active');
              //$(this).closest('.dropdown-menu').find('.active').removeClass('active');
              //$(this).addClass('active');

              //var selText = $(this).text();
              //$(this).parents('.btn-group').find('.dropdown-toggle').html(selText+' <span class="caret"></span>');

               hide();
        });


        function containsAll(needles, haystack){
              for(var i = 0 , len = needles.length; i < len; i++){
                 if($.inArray(needles[i], haystack) === -1) {return false;}
              }
              return true;
            }

        function hide() {
              var target = $('.hentry');
              var checked_values = $('.filterbtn.active').map(function () { return $(this).attr('data-filter'); }).get();
              if (!checked_values.length) {
                target.stop().fadeIn();
                //console.info('jetzt alles zeigen');
              }else{
                $(target).each(function(){
                  // get all the classes from the object:
                  var catsArray = $(this).attr('class').split(' ');
                  // variante : min 1 filter muss zutreffen:
                  var hits = false;


                  // variante 1 : alle filter müssen zutreffen:
                  if ( containsAll( checked_values,catsArray ) ) {
                      $(this).stop().fadeIn();
                      //$(this).attr('rel', 'lightbox');
                  }else{
                     $(this).stop().fadeOut( "fast", function(){
                      //$('a[rel*="lightbox"]').swipebox();
                      //$('a[rel*="lightbox"]').addClass('test');

                      //$(this).attr('rel', '');

                      //console.info('refresh lightbox');
                     });
                  }

                  // variante 2 : min 1 filter müssen zutreffen:
                  /*
                  $.each(catsArray, function(i, val){ // check if any values of this marker are in checked_values
                      if($.inArray(val, checked_values) !== -1){
                        hits = true;
                      }
                  });
                  if (!hits ) {
                      //console.info('ist UNsichtbar: ' +$(this).attr('class'));
                      $(this).stop().fadeOut();
                  }else{
                      //console.info('ist sichtbar: ' +$(this).attr('class'));
                      $(this).stop().fadeIn();
                  }
                  */

                });
              }
        } // end function hide

        // end FILTER

      } // end init
    }


  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
